.hero-banner {
    width: 100%;
    height: 100vh;
    background: url("../src/assets/hero-banner.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

@media screen and (max-width: 768px) {
    .hero-banner {
        width: 100%;
        height: 100vh;
        background: url("../src/assets/hero-banner.jpeg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
